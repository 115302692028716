import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { setColorMode } from '../utilities/functions';

import HeroText from '../components/hero-text';
import ProjectGrid from '../components/project-grid';
import { Container } from '../elements/layouts';
import Footer from '../components/footer';

const WorkPage = ({ data: { projects, datoCmsWork }, location }) => {
  const works = projects.edges;
  const { htmlAst } = datoCmsWork.headlineNode.childMarkdownRemark;

  useEffect(() => {
    setColorMode(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <HeroText headline={htmlAst} />
      <Container>
        <ProjectGrid projects={works} />
      </Container>
      <Footer pathname={location.pathname} />
    </>
  );
};

export default WorkPage;

export const query = graphql`
  query ProjectsQuery {
    projects: allDatoCmsProject(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          slug
          title
          client

          featuredImagePortrait {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    datoCmsWork {
      headlineNode {
        childMarkdownRemark {
          htmlAst
        }
      }
    }
  }
`;
